export function airtableToMap(data) {
  const nodes = Object.fromEntries(
    Object.values(data)?.[0].edges.map((e) => [
      e.node.data.Name,
      { ...e.node.data },
    ])
  );

  return nodes;
}

export function airtableToList(data) {
  const nodes = Object.values(data)?.[0].edges.map((e) => e.node.data);

  return nodes;
}

function languageSuffix(language) {
  if (language) {
    return "_" + language;
  }

  return "";
}

export function getValue(entry, key, language) {
  if (!entry || !key) {
    console.trace(entry, key);
  }
  return entry[key]["Value" + languageSuffix(language)];
}

export function getValueNa(entry, key, language) {
  if (!entry || !key) {
    console.trace(entry, key);
  }
  return entry[key]["Value" + languageSuffix(language)] || "n/a";
}

export function getKeyedValue(entry, key, language) {
  if (!entry) {
    return null;
  }

  const value = entry[`${key}${languageSuffix(language)}`];

  return value;
}

export function daysToString(days, strings) {
  if (days === 0) return "";
  if (days === 1) return days + " " + getValue(strings, "day");
  return days + " " + getValue(strings, "days");
}

export function randomFloat(min, max) {
  return Math.random() * (max - min) + min;
}

export function randomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomMember(list) {
  return list[Math.floor(Math.random() * list.length)];
}

export function range(n) {
  return [...Array(n).keys()];
}

export function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}



const pi = Math.PI;
function toRad(deg) {
  return deg * (pi / 180);
}

function toDeg(rad) {
  return rad * (180 / pi);
}

export function midPoint(lat1, lon1, lat2, lon2) {
  const dLon = toRad(lon2 - lon1);

  //convert to radians
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);
  lon1 = toRad(lon1);

  const Bx = Math.cos(lat2) * Math.cos(dLon);
  const By = Math.cos(lat2) * Math.sin(dLon);
  const lat3 = Math.atan2(
    Math.sin(lat1) + Math.sin(lat2),
    Math.sqrt((Math.cos(lat1) + Bx) * (Math.cos(lat1) + Bx) + By * By)
  );
  const lon3 = lon1 + Math.atan2(By, Math.cos(lat1) + Bx);

  return { lat: toDeg(lat3), lng: toDeg(lon3) };
}

export function filterOnline(obj) {
  return obj.Online === "Online";
}

export function degreesToDMS(dd) {
  const deg = dd | 0; // truncate dd to get degrees
  const frac = Math.abs(dd - deg); // get fractional part
  const min = (frac * 60) | 0; // multiply fraction by 60 and truncate
  const sec = Math.floor(frac * 3600 - min * 60);

  return [deg, min, sec];
}
