import clsx from "clsx";
import * as React from "react";
import { ContactForm } from "../contactForm/contactForm";

export const toasts = Object.freeze({
  submitSuccess: "Your request has been sent successfully",
  submitFail:
    "We've experienced an issue processing your request. Please try again or come back later",
});

export const FormContext = React.createContext({
  showForm: false,
  setShowForm: () => { },
  formParent: null,
  setFormParent: () => { },
  ctaText: null,
  setCtaText: () => { },
  excludeFields: [],
  setExcludeFields: () => { },
  setToast: () => { },
  extraData: {},
  setExtraData: () => { },
  toast: "",
});

const FormContextProvider = ({ children }) => {
  const [showForm, setShowFormFunc] = React.useState(false);
  const [formParent, setFormParent] = React.useState(null);
  const [ctaText, setCtaText] = React.useState(null);
  const [excludeFields, setExcludeFields] = React.useState([]);
  const [toast, setToast] = React.useState(null);
  const [extraData, setExtraData] = React.useState({});

  const createToast = (toast) => {
    setToast(toast);

    setTimeout(() => setToast(null), 2500);
  };

  const setShowForm = (shown) => {
    document.documentElement.classList[shown ? "add" : "remove"]("no-scroll");

    // document.body.style = shown ? "overflow: hidden" : "overflow: auto";
    setShowFormFunc(shown);
  };

  return (
    <FormContext.Provider
      value={{
        showForm,
        setShowForm,
        formParent,
        setFormParent,
        ctaText,
        setCtaText,
        excludeFields,
        setExcludeFields,
        toast,
        setToast: createToast,
        extraData,
        setExtraData
      }}
    >
      {children}
      {showForm && <ContactForm />}
      {toast && (
        <div className={clsx("toast", toast)}>
          <div>
            <p>{toasts[toast]}</p>
          </div>
        </div>
      )}
    </FormContext.Provider>
  );
};

export default FormContext;

export { FormContextProvider };
