/* eslint-disable indent */
import clsx from "clsx";
import React, { useState } from "react";
import { useContactForm } from "../../hooks/useContactForm";
import { getKeyedValue } from "../../utils";
import { Button } from "../button/button";
import FormContext, { toasts } from "../context/formContext";
import { callingCodes } from "./codes";
import { navigate } from "gatsby";

import { useForm } from "react-hook-form";

import "./contact-form.scss";

function DisablableSelect({
  placeholder,
  name,
  children,
  register,
  errors,
  getValues,
  required,
}) {
  return (
    <div className="select-wrapper disablable">
      <select
        defaultValue=""
        className={errors[name] && "error"}
        placeholder={placeholder}
        // style={getValues()[name] && { color: "red" }
        {...register(name, {
          required,
        })}
      >
        <option disabled selected hidden value="" key="empty">
          {placeholder}
        </option>

        {children}
      </select>

      {errors[name] && (
        <span className="form-warning">This field is required</span>
      )}
    </div>
  );
}

function PrefixPicker({ register, errors }) {
  return (
    <DisablableSelect
      name="phone_prefix"
      placeholder={"Code"}
      register={register}
      errors={errors}
      required={false}
    >
      {callingCodes.map(([country, code, countryCode]) => (
        <option data-country={country} key={country} value={code}>
          {code.padEnd(14, " \u00a0")} {country}
        </option>
      ))}
    </DisablableSelect>
  );
}

function RawFormElement({
  field,
  index,
  type,
  options,
  placeholder,
  register,
  errors,
  getValues,
  required,
}) {
  switch (type) {
    case "Single Select":
      return (
        <div className="select-wrapper">
          <DisablableSelect
            name={field}
            placeholder={placeholder}
            register={register}
            errors={errors}
            getValues={getValues}
            required={required}
          >
            {options.map((o) => (
              <option key={o} value={o}>
                {o}
              </option>
            ))}
          </DisablableSelect>
        </div>
      );

    case "Text":
    case "Date":
    case "Email":
      return (
        <>
          <input
            // name={field}
            placeholder={placeholder}
            type={type}
            {...register(field, { required })}
            className={clsx(errors[field] && "error", "arrow-parent")}
          />
          {errors[field] && (
            <span className="form-warning">This field is required</span>
          )}
        </>
      );
    case "Checkbox":
      return (
        <input
          // name={field}
          placeholder={placeholder}
          type={type}
          {...register(field)}
        />
      );
    case "Long Text":
      return (
        <textarea name={field} placeholder={placeholder} {...register(field)} />
      );
    case "Phone":
      return (
        <div className="phone-wrapper">
          <PrefixPicker register={register} errors={errors} />
          <input
            {...register(field, { required })}
            placeholder={placeholder}
            type={type}
          />
        </div>
      );
  }

  return null;
}

function FormElement({ config, index, register, errors, submitting, getValues }) {
  if (!config) {
    return null;
  }

  const { Field, Type, Options, Size, Placeholder, Required } = config;

  const style = { animationDelay: 1 + index / 20 + "s" };

  if (Type === "Separator") {
    return <div className="form-separator" style={style} />;
  }

  if (Type === "Submit") {
    if (submitting) {
      return (
        <div className="submit-wrapper" style={style}>
          <Button className="form-submit" invert type="submit" disabled>
            <p>Submitting...</p>
          </Button>
        </div>
      );
    }
    return (
      <div className="submit-wrapper" style={style}>
        <Button className="form-submit" invert type="submit">
          <p>{getKeyedValue(config, "Label")}</p>
        </Button>
      </div>
    );
  }

  const tooltip = getKeyedValue(config, "Tooltip");

  const invert = Type === "Checkbox";

  const Label = <label htmlFor={Field}>{getKeyedValue(config, "Label")}</label>;

  return (
    <div className={clsx("form-element", Type, Size, Field)} style={style}>
      {!invert && Label}
      <RawFormElement
        field={Field}
        type={Type}
        options={Options?.split(",\n")}
        placeholder={Placeholder}
        index={index}
        register={register}
        errors={errors}
        getValues={getValues}
        required={Required}
      />
      <div className="form-tooltip-container">
        {invert && Label}
        {tooltip && (
          <div className="form-tooltip">
            <div className="form-tooltip-cta">?</div>
            <p className="form-tooltip-content">{tooltip}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function MessageFormElement({ shown, setShown, config, index, register }) {
  return (
    <div className="message-wrapper">
      <div
        className={clsx("advanced-options arrow-parent", shown && "shown")}
        onClick={() => setShown(!shown)}
      >
        Add a message
      </div>
      {shown && (
        <div className="message-wrapper-message">
          <FormElement config={config} index={index} register={register} />
        </div>
      )}
    </div>
  );
}

function splitFormData(formData) {
  const idx = formData.findIndex((f) => f.Type === "Separator");

  if (!idx || idx === -1) {
    return [formData];
  }

  return [formData.slice(0, idx), formData.slice(idx)];
}

export function ContactForm() {
  const formData = useContactForm();

  const [advanced, setAdvanced] = useState(false);

  const { register, handleSubmit, watch, formState, getValues } = useForm();

  const { errors } = formState;

  const [submitting, setSubmitting] = useState(false);

  return (
    <FormContext.Consumer>
      {({
        showForm,
        setShowForm,
        formParent,
        setFormParent,
        ctaText,
        excludeFields,
        setToast,
        extraData,
      }) => {
        const onSubmit = (data) => {
          setSubmitting(true);
          fetch("https://formsubmit.co/ajax/8eb9db4c89833180df933f66fedaa0e2", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              ...data,
              ...extraData,
              Source: window?.location?.href,
              _webhook:
                "https://hooks.airtable.com/workflows/v1/genericWebhook/appBXJpo2rrIcsGUI/wflz1rzuPs2x09Yzw/wtr29oMRbEFRQjVTo",
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.success === "false") {
                setToast("submitFail");
                setSubmitting(false);
              } else {
                navigate("/thanks");
                // setToast("submitSuccess");
                setShowForm(false);
              }
            })
            .catch((error) => console.log(error));
        };

        const splitData = splitFormData(
          formData.filter((c) => {
            return !excludeFields || !excludeFields?.includes(c.Field);
          })
        );

        const initialFormLeft = formParent.getBoundingClientRect().left + "px";
        const initialFormRight =
          window.innerWidth - formParent.getBoundingClientRect().right + "px";

        const initialFormTop = formParent.getBoundingClientRect().top + "px";
        const initialFormBottom =
          formParent.getBoundingClientRect().bottom + "px";

        const initalFormWidth = formParent.offsetWidth + "px";
        const initialFormHeight = formParent.offsetHeight + "px";

        document.documentElement.style.setProperty(
          "--initial-form-bottom",
          initialFormBottom
        );

        document.documentElement.style.setProperty(
          "--initial-form-top",
          initialFormTop
        );

        document.documentElement.style.setProperty(
          "--initial-form-left",
          initialFormLeft
        );

        document.documentElement.style.setProperty(
          "--initial-form-width",
          initalFormWidth
        );
        document.documentElement.style.setProperty(
          "--initial-form-height",
          initialFormHeight
        );

        return (
          <div
            className="contact-form-wrapper"
            onClick={(e) => {
              if (e.target?.className === "contact-form-wrapper") {
                setShowForm(false);
              }
            }}
          >
            <div
              className="contact-form-container"
              style={{
                animationDelay: "0s",
                top: initialFormTop,
                bottom: initialFormBottom,
                left: initialFormLeft,
                right: initialFormRight,
                width: formParent.offsetWidth,
                maxHeight: initialFormHeight,
              }}
            >
              {/* <div className="contact-form" */}
              <form
                className={clsx("contact-form")}
                // target="_blank"
                // action="https://formsubmit.co/pjero@dubrovnikcharter.com"
                // method="POST"
                onSubmit={handleSubmit(onSubmit)}
              >
                <button
                  onClick={() => setShowForm(false)}
                  className="form-close"
                >
                  <div style={{ transform: "rotate(45deg)" }} />
                  <div style={{ transform: "rotate(-45deg)" }} />
                </button>

                <p className="form-cta">{ctaText}</p>
                <input
                  type="hidden"
                  {...register("_webhook", { required: false })}
                  // name="_webhook"
                  value="https://hooks.airtable.com/workflows/v1/genericWebhook/appBXJpo2rrIcsGUI/wflz1rzuPs2x09Yzw/wtr29oMRbEFRQjVTo"
                />
                {/* <input
                  type="hidden"
                  name="_next"
                  value={`${window.location.protocol}//${window?.location.hostname}:${window?.location.port}/thanks`}
                /> */}
                <input
                  type="hidden"
                  name="_source"
                  value={window.location.href}
                />
                <input
                  type="hidden"
                  {...register("_url", { required: false })}
                  value="https://www.dubrovnik-charter.com"
                />
                <div className="form-sections">
                  {splitData.map((fd, i) => {
                    if (!fd.length) {
                      return null;
                    }

                    return (
                      // eslint-disable-next-line react/jsx-key
                      <div className="form-section" key={i}>
                        {fd.map(
                          (c, index) =>
                            c.Type !== "Submit" &&
                            (c.Field === "Message" ? (
                              <MessageFormElement
                                key={index + c.Field + c.Type}
                                config={c}
                                index={index}
                                setShown={setAdvanced}
                                shown={advanced}
                                register={register}
                              />
                            ) : (
                              <FormElement
                                key={index + c.Field + c.Type}
                                config={c}
                                index={index}
                                register={register}
                                errors={errors}
                                getValues={getValues}
                              />
                            ))
                        )}
                      </div>
                    );
                  })}
                </div>

                <FormElement
                  submitting={submitting}
                  config={splitData[splitData.length - 1].find(
                    (e) => e.Type === "Submit"
                  )}
                  index={5}
                />
              </form>
            </div>
          </div>
        );
      }}
    </FormContext.Consumer>
  );
}
