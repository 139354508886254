import * as React from "react";

import clsx from "clsx";
import { Link } from "gatsby";

import "./button.scss";

const Button = React.forwardRef(
  (
    {
      className,
      type,
      children,
      fullWidth,
      invert,
      invertDesktop,
      href,
      onClick,
      sticky,
      external
    },
    ref
  ) => {
    const fullClassName = clsx(
      "button",
      className,
      fullWidth && "full-width",
      invertDesktop && "invert-desktop",
      invert && "invert",
      sticky && "sticky"
    );

    if (href && external) {
      return (
        <a
          ref={ref}
          type={type}
          onClick={onClick}
          className={fullClassName}
          href={href}
        >
          {children}
        </a>
      );
    }
    if(href){
      return <Link
        ref={ref}
        type={type}
        onClick={onClick}
        className={fullClassName}
        to={href}
      >
        {children}
      </Link>;
    }
    return (
      <button ref={ref} type={type} onClick={onClick} className={fullClassName}>
        {children}
      </button>
    );
  }
);

Button.displayName = "Button";

export { Button };
