export const callingCodes = [
  ["Afghanistan", "+93", "AF"],
  ["Aland Islands", "+358", "AX"],
  ["Albania", "+355", "AL"],
  ["Algeria", "+213", "DZ"],
  ["AmericanSamoa", "+1684", "AS"],
  ["Andorra", "+376", "AD"],
  ["Angola", "+244", "AO"],
  ["Anguilla", "+1264", "AI"],
  ["Antarctica", "+672", "AQ"],
  ["Antigua and Barbuda", "+1268", "AG"],
  ["Argentina", "+54", "AR"],
  ["Armenia", "+374", "AM"],
  ["Aruba", "+297", "AW"],
  ["Australia", "+61", "AU"],
  ["Austria", "+43", "AT"],
  ["Azerbaijan", "+994", "AZ"],
  ["Bahamas", "+1242", "BS"],
  ["Bahrain", "+973", "BH"],
  ["Bangladesh", "+880", "BD"],
  ["Barbados", "+1246", "BB"],
  ["Belarus", "+375", "BY"],
  ["Belgium", "+32", "BE"],
  ["Belize", "+501", "BZ"],
  ["Benin", "+229", "BJ"],
  ["Bermuda", "+1441", "BM"],
  ["Bhutan", "+975", "BT"],
  ["Bolivia, Plurinational State of", "+591", "BO"],
  ["Bosnia and Herzegovina", "+387", "BA"],
  ["Botswana", "+267", "BW"],
  ["Brazil", "+55", "BR"],
  ["British Indian Ocean Territory", "+246", "IO"],
  ["Brunei Darussalam", "+673", "BN"],
  ["Bulgaria", "+359", "BG"],
  ["Burkina Faso", "+226", "BF"],
  ["Burundi", "+257", "BI"],
  ["Cambodia", "+855", "KH"],
  ["Cameroon", "+237", "CM"],
  ["Canada", "+1", "CA"],
  ["Cape Verde", "+238", "CV"],
  ["Cayman Islands", "+ 345", "KY"],
  ["Central African Republic", "+236", "CF"],
  ["Chad", "+235", "TD"],
  ["Chile", "+56", "CL"],
  ["China", "+86", "CN"],
  ["Christmas Island", "+61", "CX"],
  ["Cocos (Keeling) Islands", "+61", "CC"],
  ["Colombia", "+57", "CO"],
  ["Comoros", "+269", "KM"],
  ["Congo", "+242", "CG"],
  ["Congo, The Democratic Republic of the Congo", "+243", "CD"],
  ["Cook Islands", "+682", "CK"],
  ["Costa Rica", "+506", "CR"],
  ["Cote d'Ivoire", "+225", "CI"],
  ["Croatia", "+385", "HR"],
  ["Cuba", "+53", "CU"],
  ["Cyprus", "+357", "CY"],
  ["Czech Republic", "+420", "CZ"],
  ["Denmark", "+45", "DK"],
  ["Djibouti", "+253", "DJ"],
  ["Dominica", "+1767", "DM"],
  ["Dominican Republic", "+1849", "DO"],
  ["Ecuador", "+593", "EC"],
  ["Egypt", "+20", "EG"],
  ["El Salvador", "+503", "SV"],
  ["Equatorial Guinea", "+240", "GQ"],
  ["Eritrea", "+291", "ER"],
  ["Estonia", "+372", "EE"],
  ["Ethiopia", "+251", "ET"],
  ["Falkland Islands (Malvinas)", "+500", "FK"],
  ["Faroe Islands", "+298", "FO"],
  ["Fiji", "+679", "FJ"],
  ["Finland", "+358", "FI"],
  ["France", "+33", "FR"],
  ["French Guiana", "+594", "GF"],
  ["French Polynesia", "+689", "PF"],
  ["Gabon", "+241", "GA"],
  ["Gambia", "+220", "GM"],
  ["Georgia", "+995", "GE"],
  ["Germany", "+49", "DE"],
  ["Ghana", "+233", "GH"],
  ["Gibraltar", "+350", "GI"],
  ["Greece", "+30", "GR"],
  ["Greenland", "+299", "GL"],
  ["Grenada", "+1473", "GD"],
  ["Guadeloupe", "+590", "GP"],
  ["Guam", "+1671", "GU"],
  ["Guatemala", "+502", "GT"],
  ["Guernsey", "+44", "GG"],
  ["Guinea", "+224", "GN"],
  ["Guinea-Bissau", "+245", "GW"],
  ["Guyana", "+595", "GY"],
  ["Haiti", "+509", "HT"],
  ["Holy See (Vatican City State)", "+379", "VA"],
  ["Honduras", "+504", "HN"],
  ["Hong Kong", "+852", "HK"],
  ["Hungary", "+36", "HU"],
  ["Iceland", "+354", "IS"],
  ["India", "+91", "IN"],
  ["Indonesia", "+62", "ID"],
  ["Iran, Islamic Republic of Persian Gulf", "+98", "IR"],
  ["Iraq", "+964", "IQ"],
  ["Ireland", "+353", "IE"],
  ["Isle of Man", "+44", "IM"],
  ["Israel", "+972", "IL"],
  ["Italy", "+39", "IT"],
  ["Jamaica", "+1876", "JM"],
  ["Japan", "+81", "JP"],
  ["Jersey", "+44", "JE"],
  ["Jordan", "+962", "JO"],
  ["Kazakhstan", "+77", "KZ"],
  ["Kenya", "+254", "KE"],
  ["Kiribati", "+686", "KI"],
  ["Korea, Democratic People's Republic of Korea", "+850", "KP"],
  ["Korea, Republic of South Korea", "+82", "KR"],
  ["Kuwait", "+965", "KW"],
  ["Kyrgyzstan", "+996", "KG"],
  ["Laos", "+856", "LA"],
  ["Latvia", "+371", "LV"],
  ["Lebanon", "+961", "LB"],
  ["Lesotho", "+266", "LS"],
  ["Liberia", "+231", "LR"],
  ["Libyan Arab Jamahiriya", "+218", "LY"],
  ["Liechtenstein", "+423", "LI"],
  ["Lithuania", "+370", "LT"],
  ["Luxembourg", "+352", "LU"],
  ["Macao", "+853", "MO"],
  ["Macedonia", "+389", "MK"],
  ["Madagascar", "+261", "MG"],
  ["Malawi", "+265", "MW"],
  ["Malaysia", "+60", "MY"],
  ["Maldives", "+960", "MV"],
  ["Mali", "+223", "ML"],
  ["Malta", "+356", "MT"],
  ["Marshall Islands", "+692", "MH"],
  ["Martinique", "+596", "MQ"],
  ["Mauritania", "+222", "MR"],
  ["Mauritius", "+230", "MU"],
  ["Mayotte", "+262", "YT"],
  ["Mexico", "+52", "MX"],
  ["Micronesia, Federated States of Micronesia", "+691", "FM"],
  ["Moldova", "+373", "MD"],
  ["Monaco", "+377", "MC"],
  ["Mongolia", "+976", "MN"],
  ["Montenegro", "+382", "ME"],
  ["Montserrat", "+1664", "MS"],
  ["Morocco", "+212", "MA"],
  ["Mozambique", "+258", "MZ"],
  ["Myanmar", "+95", "MM"],
  ["Namibia", "+264", "NA"],
  ["Nauru", "+674", "NR"],
  ["Nepal", "+977", "NP"],
  ["Netherlands", "+31", "NL"],
  ["Netherlands Antilles", "+599", "AN"],
  ["New Caledonia", "+687", "NC"],
  ["New Zealand", "+64", "NZ"],
  ["Nicaragua", "+505", "NI"],
  ["Niger", "+227", "NE"],
  ["Nigeria", "+234", "NG"],
  ["Niue", "+683", "NU"],
  ["Norfolk Island", "+672", "NF"],
  ["Northern Mariana Islands", "+1670", "MP"],
  ["Norway", "+47", "NO"],
  ["Oman", "+968", "OM"],
  ["Pakistan", "+92", "PK"],
  ["Palau", "+680", "PW"],
  ["Palestinian Territory, Occupied", "+970", "PS"],
  ["Panama", "+507", "PA"],
  ["Papua New Guinea", "+675", "PG"],
  ["Paraguay", "+595", "PY"],
  ["Peru", "+51", "PE"],
  ["Philippines", "+63", "PH"],
  ["Pitcairn", "+872", "PN"],
  ["Poland", "+48", "PL"],
  ["Portugal", "+351", "PT"],
  ["Puerto Rico", "+1939", "PR"],
  ["Qatar", "+974", "QA"],
  ["Romania", "+40", "RO"],
  ["Russia", "+7", "RU"],
  ["Rwanda", "+250", "RW"],
  ["Reunion", "+262", "RE"],
  ["Saint Barthelemy", "+590", "BL"],
  ["Saint Helena, Ascension and Tristan Da Cunha", "+290", "SH"],
  ["Saint Kitts and Nevis", "+1869", "KN"],
  ["Saint Lucia", "+1758", "LC"],
  ["Saint Martin", "+590", "MF"],
  ["Saint Pierre and Miquelon", "+508", "PM"],
  ["Saint Vincent and the Grenadines", "+1784", "VC"],
  ["Samoa", "+685", "WS"],
  ["San Marino", "+378", "SM"],
  ["Sao Tome and Principe", "+239", "ST"],
  ["Saudi Arabia", "+966", "SA"],
  ["Senegal", "+221", "SN"],
  ["Serbia", "+381", "RS"],
  ["Seychelles", "+248", "SC"],
  ["Sierra Leone", "+232", "SL"],
  ["Singapore", "+65", "SG"],
  ["Slovakia", "+421", "SK"],
  ["Slovenia", "+386", "SI"],
  ["Solomon Islands", "+677", "SB"],
  ["Somalia", "+252", "SO"],
  ["South Africa", "+27", "ZA"],
  ["South Sudan", "+211", "SS"],
  ["South Georgia and the South Sandwich Islands", "+500", "GS"],
  ["Spain", "+34", "ES"],
  ["Sri Lanka", "+94", "LK"],
  ["Sudan", "+249", "SD"],
  ["Suriname", "+597", "SR"],
  ["Svalbard and Jan Mayen", "+47", "SJ"],
  ["Swaziland", "+268", "SZ"],
  ["Sweden", "+46", "SE"],
  ["Switzerland", "+41", "CH"],
  ["Syrian Arab Republic", "+963", "SY"],
  ["Taiwan", "+886", "TW"],
  ["Tajikistan", "+992", "TJ"],
  ["Tanzania, United Republic of Tanzania", "+255", "TZ"],
  ["Thailand", "+66", "TH"],
  ["Timor-Leste", "+670", "TL"],
  ["Togo", "+228", "TG"],
  ["Tokelau", "+690", "TK"],
  ["Tonga", "+676", "TO"],
  ["Trinidad and Tobago", "+1868", "TT"],
  ["Tunisia", "+216", "TN"],
  ["Turkey", "+90", "TR"],
  ["Turkmenistan", "+993", "TM"],
  ["Turks and Caicos Islands", "+1649", "TC"],
  ["Tuvalu", "+688", "TV"],
  ["Uganda", "+256", "UG"],
  ["Ukraine", "+380", "UA"],
  ["United Arab Emirates", "+971", "AE"],
  ["United Kingdom", "+44", "GB"],
  ["United States", "+1", "US"],
  ["Uruguay", "+598", "UY"],
  ["Uzbekistan", "+998", "UZ"],
  ["Vanuatu", "+678", "VU"],
  ["Venezuela, Bolivarian Republic of Venezuela", "+58", "VE"],
  ["Vietnam", "+84", "VN"],
  ["Virgin Islands, British", "+1284", "VG"],
  ["Virgin Islands, U.S.", "+1340", "VI"],
  ["Wallis and Futuna", "+681", "WF"],
  ["Yemen", "+967", "YE"],
  ["Zambia", "+260", "ZM"],
  ["Zimbabwe", "+263", "ZW"],
];
