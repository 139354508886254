import { graphql, useStaticQuery } from "gatsby";

import { airtableToList } from "../utils";

export const useContactForm = () => {
  const data = useStaticQuery(graphql`
    query ContactFormQuery {
      allAirtableContactForm {
        edges {
          node {
            data {
              Field
              Type
              Label
              Options
              Tooltip
              Size
              Required
              Order
              Placeholder
              Short_Form
            }
          }
        }
      }
    }
  `);

  return airtableToList(data).sort((a, b) => a.Order - b.Order);
};
