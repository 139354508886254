exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-brochure-[slug]-js": () => import("./../../../src/pages/brochure/[slug].js" /* webpackChunkName: "component---src-pages-brochure-[slug]-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experiences-js": () => import("./../../../src/pages/experiences.js" /* webpackChunkName: "component---src-pages-experiences-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-fleet-js": () => import("./../../../src/pages/fleet.js" /* webpackChunkName: "component---src-pages-fleet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inprint-js": () => import("./../../../src/pages/inprint.js" /* webpackChunkName: "component---src-pages-inprint-js" */),
  "component---src-pages-magazine-js": () => import("./../../../src/pages/magazine.js" /* webpackChunkName: "component---src-pages-magazine-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-routes-js": () => import("./../../../src/pages/routes.js" /* webpackChunkName: "component---src-pages-routes-js" */),
  "component---src-pages-tags-[tag]-js": () => import("./../../../src/pages/tags/[tag].js" /* webpackChunkName: "component---src-pages-tags-[tag]-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-boat-category-js": () => import("./../../../src/templates/boatCategory.js" /* webpackChunkName: "component---src-templates-boat-category-js" */),
  "component---src-templates-boat-js": () => import("./../../../src/templates/boat.js" /* webpackChunkName: "component---src-templates-boat-js" */),
  "component---src-templates-experience-js": () => import("./../../../src/templates/experience.js" /* webpackChunkName: "component---src-templates-experience-js" */),
  "component---src-templates-route-js": () => import("./../../../src/templates/route.js" /* webpackChunkName: "component---src-templates-route-js" */)
}

